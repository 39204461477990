<template>
    <component 
        :is="wrapperElement"
        :to="type === 'internal' ? `/${uri}` : null"
        :href="type === 'external' ? externalUrl : null"
        class="author-chip"
    >
        <ImageAsset
            v-if="image"
            class="author-chip__image"
            :image="image"
            sizes="48px"
            alt=""
        />
        <div class="author-chip__info">
            <p
                class="author-chip__name"
            >
                {{ name }}
            </p>
            <p
                class="author-chip__role"
            >
                {{ role }}
            </p>
        </div>
    </component>
</template>

<script setup>
const props = defineProps({
    name: {
        type: String,
        required: true,
        default: ''
    },
    role: {
        type: String,
        required: true,
        default: ''
    },
    uri: {
        type: String,
        required: false,
        default: ''
    },
    externalUrl: {
        type: String,
        required: false,
        default: ''
    },
    image: {
        type: Object,
        required: false,
        default() {
            return {};
        }
    }
});

const type = computed(() => {
    if (props.uri) {
        return 'internal';
    } else if (props.externalUrl) {
        return 'external';
    } else {
        return 'no-link';
    }
});

const wrapperElement = computed(() => {
    if (type.value === 'internal') {
        return defineNuxtLink({});
    } else if (type.value === 'external') {
        return 'a';
    } else {
        return 'div';
    }
});
</script>


<style src="./AuthorChip.less" lang="less" scoped />
